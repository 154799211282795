@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ol {
  list-style: decimal;
  margin-left: 2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* productCard stock css abb 28-10-24 */

.productCardStock {
  font-size: 0.7em;
  font-weight: 600;
}

/* productModal productDetails css abb 28-10-24 */
.inDetails {
  border: solid 1px #ccc;
  padding: 1em;
}

.inDetails ul {
  list-style-type: disc;
  padding-left: 2em;
}